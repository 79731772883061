import PropertyCard from "@components/PropertyCard/PropertyCard"
import Animation from "@components/elements/Animation"
import Slider from "@components/elements/Slider/SSlider"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./SignaturePropertiesSlider.scss"
import useLimitSignatureProperties from "@hooks/dataHooks/useLimitSignatureProperties"
import GGFXImage from "@components/elements/GGFXImage"
import { parseHTML } from "@lib/utils"
import clsx from "clsx"
import CTALink from "@components/elements/CTALink"
import CurrencyPrice from "@components/CurrencyPrice/CurrencyPrice"

const SignaturePropertiesSlider = ({
  module,
  properties,
  strapiID,
  ggfx_results,
}) => {
  const { propertiesSing } = useLimitSignatureProperties(properties)

  // if (!module) return null
  const { description, cta_text } = module

  setTimeout(() => {
    if(typeof window !== "undefined" && window?.location?.hash === "#singnature") {
      const element = document.getElementById(`singnature`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }   
  },2000)

  return (
    <Animation animateInType="up" className="singnature-slider-module-wrap" id="singnature">
      <Container className="singnature-slider-module-container">
        <Row>
          <Col lg="12" xl="3">
            <div className="content-section">
              <GGFXImage
                ImageSrc={module?.logo_image}
                altText="banner-bg"
                imagetransforms={ggfx_results}
                renderer="srcSet"
                imagename="page.featured_prop_slider_logo_image.logo_image"
                strapiID={strapiID}
              />
              {/* <img src={img} /> */}
              <Row>
                <Col md="9" xl="12">
                  <div className="content">
                    {/* {parseHTML(description?.data?.description)} */}
                    <p><span><strong>Our high-net-worth and private client division</strong>. Signature property listings starting from <CurrencyPrice price="20,000,000" />.</span></p>
                  </div>
                </Col>
                <Col md="3" xl="12">
                  <div className="cta-section">
                    <CTALink
                      cta={cta_text?.cta}
                      // key={cta.id}
                      className={clsx("button", "button-orange")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg="12" xl="1" />
          <Col lg="12" xl="8">
            <div>
              <div className="singnature-slider-tab-section">
                <Slider size="sm">
                  {propertiesSing.length &&
                    propertiesSing
                      .slice(0, 6)
                      .map((property, index) => (
                        <PropertyCard
                          key={property.id}
                          address={property.display_address}
                          ammenities={property.accommodation_summary}
                          description={property.description}
                          bathrooms={property.bathroom}
                          bedrooms={property.bedroom}
                          building={property.building}
                          image={property.images?.[0]}
                          images={property?.images}
                          price={property.price}
                          size={property.floorarea_min}
                          index={index}
                          email={property?.crm_negotiator_id?.email}
                          crm_negotiator_id={property?.crm_negotiator_id?.id}
                          crmID={property?.crm_id}
                          slug={property?.slug}
                          searchType={property?.search_type}
                          imagetransforms={property?.ggfx_results}
                          strapiID={property?.strapi_id}
                          department={property?.department}
                          slider
                          isSingnature
                          noContact
                        />
                      ))}
                </Slider>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Animation>
  )
}

export default SignaturePropertiesSlider
