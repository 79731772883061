import AutoPopup from "@components/AutoPopup/AutoPopup"
import BannerHome from "@components/Banner/BannerHome"
// import ContactFomModule from "@components/ContactFomModule/ContactFomModule"
// import AdsBanner from "@components/AdsBanner/AdsBanner"
import Layout from "@components/Layout/Layout"
import OurPartner from "@components/OurPartner/OurPartner"
import QuestionnaireBanner from "@components/QuestionnaireBanner/QuestionnaireBanner"
import SignaturePropertiesSlider from "@components/SignaturePropertiesSlider/SignaturePropertiesSlider"
import Seo from "@components/seo"
import loadable from "@loadable/component"
import "@styles/main.scss"
import { graphql } from "gatsby"
import React from "react"
import { useLocation } from "@reach/router";
// const ContentLinks = loadable(() =>
//   import("@components/ContentLinks/ContentLinks")
// )
// const DubaiCommunities = loadable(() =>
//   import("@components/DubaiCommunities/DubaiCommunities")
// )
// const NewsSlider = loadable(() => import("@components/NewsSlider/NewsSlider"))
// const FeaturedPropertiesSlider = loadable(() =>
//   import("@components/FeaturedPropertiesSlider/FeaturedPropertiesSlider")
// )
// import ContentLinks from "@components/ContentLinks/ContentLinks"
// import DeveloperSlider from "@components/DeveloperSlider/DeveloperSlider"
// import DubaiCommunities from "@components/DubaiCommunities/DubaiCommunities"
// import FeaturedPropertiesSlider from "@components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"
// import NewsSlider from "@components/NewsSlider/NewsSlider"
const ContentLinks = loadable(() =>
  import("@components/ContentLinks/ContentLinks")
)
const NewsSlider = loadable(() => import("@components/NewsSlider/NewsSlider"))
const FeaturedPropertiesSlider = loadable(() =>
  import("@components/FeaturedPropertiesSlider/FeaturedPropertiesSlider")
)
const DubaiCommunities = loadable(() =>
  import("@components/DubaiCommunities/DubaiCommunities")
)
const AdsBanner = loadable(() => import("@components/AdsBanner/AdsBanner"))
const DeveloperSlider = loadable(() =>
  import("@components/DeveloperSlider/DeveloperSlider")
)
const ContactFomModule = loadable(() =>
  import("@components/ContactFomModule/ContactFomModule")
)

const HomeTemplate = ({ data }) => {
  const pageData = data?.strapiPage
  const modules = pageData?.modules
  const banner = pageData?.banner
  // const imagetransforms = pageData?.imagetransforms
  const strapiID = pageData?.strapi_id
  const location = useLocation();
  const open = location.state?.open || false;
  return (
    <Layout pageData={pageData}>
      <BannerHome
        bannerData={banner}
        ggfx_results={pageData?.ggfx_results}
        strapiID={strapiID}
      />
      <AutoPopup />
      {modules?.map((module) => (
        <React.Fragment key={module.id}>
          {module?.strapi_component === "modules.content-and-links" && (
            <ContentLinks module={module} />
          )}
          {module?.strapi_component === "modules.ads-banner" && (
            <AdsBanner module={module} />
          )}
          {module?.strapi_component === "modules.questionnaire" && (
          <QuestionnaireBanner  ggfx_results={pageData?.ggfx_results}
          strapiID={strapiID} bannerData={module} open={open}/>
          )}

          {module?.strapi_component === "modules.featured-prop-slider" &&
            !module?.is_signature && (
              <FeaturedPropertiesSlider module={module} />
            )}
          {module?.strapi_component === "modules.featured-prop-slider" &&
            module?.is_signature && (
              <SignaturePropertiesSlider
                module={module}
                ggfx_results={pageData?.ggfx_results}
                strapiID={strapiID}
              />
            )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "contact_module" && (
              <ContactFomModule module={module} />
            )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "dubai_communities" && (
              <DubaiCommunities module={module} />
            )}
          {module?.strapi_component === "modules.featured-news" && (
            <NewsSlider module={module} />
          )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "developer_slider" && (
              <DeveloperSlider module={module} />
            )}
             {module?.strapi_component === "modules.partner" && (
            <>
            <OurPartner
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
            </>
          )}
        </React.Fragment>
      ))}
      {/* <SignaturePropertiesSlider
        module={module}
        strapiID={strapiID}
        imagetransforms={imagetransforms}
      /> */}
    </Layout>
  )
}

export default HomeTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  return (
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_CONTENT_AND_LINKS {
          ...contentAndLinks
        }
        ... on STRAPI__COMPONENT_MODULES_ADS_BANNER {
          ...adsBanner
        }
        ... on STRAPI__COMPONENT_MODULES_GLOBAL_MODULE {
          ...globalModule
        }
        ... on STRAPI__COMPONENT_MODULES_QUESTIONNAIRE {
          ...questionnaireFragment
        }
        ... on STRAPI__COMPONENT_MODULES_FEATURED_PROP_SLIDER {
          ...featuredProperties
        }
        ... on STRAPI__COMPONENT_MODULES_PARTNER {
          ...partner
        }
        ... on STRAPI__COMPONENT_MODULES_FEATURED_NEWS {
          ...featuredNews
        }
      }
    }
  }
`
